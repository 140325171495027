import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Honey for peace"
const PAGE_NAME = "honey"
const HoneyPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-6">
            <p>
              <br />
              <br />
              <br />
              <br />
              This program aims to improve healthy, social and economical life
              of disadvantaged youth (ex-combatants) and vulnerable rural
              community, to fight against youth unemployment and idleness and to
              prevent idle youth from being recruited in armed groups and
              perpetrating violence in communities. Initiating youth and all the
              community for the environment and biodiversity protection.
            </p>
            <p>
              Beneficiaries will be trained in Beekeeping and in
              entrepreneurship so they are able to produce honey which they will
              be selling to generate incomes. Beekeeping as an INCOME GENERATING
              activity will creating job for youth, and fight idleness and
              unemployment which are some of causes that push youth to be
              enrolled easily in armed groups, and make violence. They will be
              gathered in a cooperative of honey producers and sellers. They
              will grow their incomes and grow local economy.
            </p>
          </div>
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"honey611"} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"honey92"} />
          </div>
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"honey1182"} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HoneyPage
